<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Supplier Orders</h3>
        </div>
        <router-link
          to="/orders/supplier/add"
          class="btn btn-primary align-self-center"
          >Add Supplier Order</router-link
        >
      </div>
      <div class="card-body p-7">

        <div class="row mb-3">
          <div class="col-12 col-lg-4 mb-3 mb-lg-0">
            <label class="col-form-label fw-bold fs-6">Order Status</label>
            <select class="form-select" v-model="additionalFilters.order_status_id">
              <option value="null" selected disabled>Select Status</option>
              <option value="">All Orders</option>
              <option :value="item.id" v-for="(item,key) in store.getters.myOrderStatuses" :key="key">{{item.name}}</option>
            </select>
          </div>
        </div>
        <div class="row mb-10">
          <div class="col-12 col-lg-4 mb-3 mb-lg-0">
            <div class="row">
              <div class="col-6">
                <label class="col-form-label fw-bold fs-6">Start Date</label>
                <input type="date" class="form-control" v-model="additionalFilters.startDate">
              </div>
              <div class="col-6">
                <label class="col-form-label fw-bold fs-6">End Date</label>
                <input type="date" class="form-control" v-model="additionalFilters.endDate">
              </div>
            </div>

          </div>
        </div>

        <Table :columns="columns" ref="table" :action="actions.SEARCH_SUPPLIER_ORDERS" :additionalFilters="additionalFilters">


          <template v-slot:orderDate="slotProps">
            <div>{{moment(new Date(slotProps.row.orderDate)).format('DD MMM YYYY')}}</div>
          </template>

          <template v-slot:cost="slotProps">
            <div>{{ numeral(slotProps.row.cost).format('0,0.00000') }}</div>
          </template>

          <template v-slot:actions="slotProps">

            <div>

              <div class="btn-group" role="group" aria-label="Basic example">

                <router-link :to="'/orders/supplier/edit/'+slotProps.row.order_id" class="btn btn-sm btn-light-warning font-weight-bold"><i class="fs-1-3 la la-eye"></i></router-link>

              </div>

            </div>

          </template>

        </Table>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/SupplierOrderEnums.ts";
import { Actions as OrderStatusActions } from '@/store/enums/OrderStatusEnums.ts';
import Table from '@/components/custom/Table.vue';
import moment from 'moment'
import numeral from 'numeral'


export default {
  name : 'SupplierOrdersList',
  components : {
    Table
  },
  setup(props,{slots}){

    const store = useStore();

    store.dispatch(OrderStatusActions.GET_ORDER_STATUSES);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Supplier Orders",[
        {title : "Supplier Orders List",to : "/orders/supplier/list"}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })

    const additionalFilters = reactive({
      startDate : moment(new Date('2021-01-01')).format('YYYY-MM-DD'),
      endDate : moment(new Date()).format('YYYY-MM-DD'),
      order_status_id : 2
    })

    const columns = [
      {name : 'reference', title : 'Ref', sortable : true, sort : null, searchable : true},
      {name : 'status', title : 'Order Status', sortable : true, sort : null, searchable : false},
      {name : 'orderDate', title : 'Order Date', sortable : true, sort : 'desc', searchable : true},
      {name : 'supplier', title : 'Supplier',sortable : true, sort : null, searchable : true},
      {name : 'products', title : 'Products',sortable : false, sort : null, searchable : true},
      {name : 'num_of_products', title : 'Num. of Products',sortable : false, sort : null, searchable : false},
      {name : 'num_of_items', title : 'Num. of Items',sortable : false, sort : null, searchable : false},
      {name : 'cost', title : 'Cost',sortable : false, sort : null, searchable : false},
      {name : 'actions', title : 'View Order',sortable : false, sort : null, searchable : false}
    ]

    return {
      columns,
      actions,
      additionalFilters,
      store,
      moment,
      numeral
    }

  }
}
</script>
